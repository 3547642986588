import { tryJSONParse } from '../../utility/json';
import { isWindowDefined, windowLocationHostname } from '../window';

// memoized flag
let _cookiesSupported = null;

/**
 * Low-level helpers
 */
function _documentCookieGet() {
  return document.cookie;
}

function _documentCookieSet(val) {
  document.cookie = val;
}

function _navigatorCookieEnabled() {
  return window.navigator.cookieEnabled;
}

/**
 * Internal getter/setter
 */
function _delete(key) {
  const cookie = _get(key);
  _documentCookieSet(`${key}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=${windowLocationHostname.get().toString()};`);

  return cookie;
}

function _get(key) {
  const result = _documentCookieGet().match(new RegExp(`${key}=([^;]+)`));
  if (!result || !result[1]) return null;

  return tryJSONParse(result[1], result[1]);
}

function _set(key, value) {
  _documentCookieSet(`${key}=${JSON.stringify(value)}; path=/; domain=${windowLocationHostname.get().toString()};`);
}

/**
 * Public methods
 */
export function areCookiesSupported() {
  if (_cookiesSupported === null) {
    if (isWindowDefined && _navigatorCookieEnabled()) {
      _set('testcookie', '1');
      const cookie = _get('testcookie');

      if (cookie) {
        _delete('testcookie');
      }

      _cookiesSupported = !!cookie;
    } else {
      _cookiesSupported = false;
    }
  }

  return _cookiesSupported;
}

export function deleteCookie(key) {
  return areCookiesSupported() ? _delete(key) : null;
}

export function getCookie(key) {
  return areCookiesSupported() ? _get(key) : null;
}

export function setCookie(key, value) {
  return areCookiesSupported() ? _set(key, value) : null;
}

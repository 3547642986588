import React from 'react';
import PropTypes from 'prop-types';

import typography from '../../styles/global_ui/typography.css';

const Icon = ({ className, disabled, id, name, onClick, size, style }) => {
  const _onClickProxy = (e) => {
    if (disabled) return e.preventDefault();
    onClick();
  };
  const sizeClass = typography[`icon${size}`];

  return (
    <svg
      className={`${typography.icon} ${sizeClass} ${className} ${disabled && typography.iconDisabled}`}
      id={id}
      onClick={_onClickProxy}
      style={style}
    >
      <use xlinkHref={`#svg-${name}`} />
    </svg>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
};

Icon.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => {},
  size: '',
  style: {},
};

export default Icon;

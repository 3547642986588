import * as Sentry from '@sentry/react';

const { SENTRY_DSN, SENTRY_ENV, SENTRY_SAMPLE_RATE } = CLIENT_ENV;

export default function initSentry() {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'http://api.localhost.local:5000',
          /^https:\/\/api-?\w*\.hackster\.io/,
        ],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: Number(SENTRY_SAMPLE_RATE),
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.2,
  });
}

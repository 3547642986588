import React from 'react';

import ChannelCard from '../../../../client/cards/channel_card';
import ContestCard from '../../../../client/cards/contest_card';
import DummyLogoCard from '../../../../client/cards/simple_logo_card/DummyCard';
import DummyProjectCard from '../../../../client/cards/project_card/DummyCard';
import DummyVideoCard from '../../../../client/cards/video_card/DummyCard';
import LogoCard from '../../../../client/cards/simple_logo_card';
import ProjectCard from '../../../../client/cards/project_card';
import VideoCard from '../../../../client/cards/video_card';

import { dummyProjectProps, projectProps } from './shared';
import { negativeFacetsForList } from '../helpers/facets';

import { getInObj } from '../../../../utility/accessors';

import viewAllStyles from '../../../../client/wrappers/view_all/view_all.css';

const SECTION_KEY_TO_VIEW_METHOD = {
  category_id: '_viewAllList',
  contest_id: '_pinnedItemProjectsListView',
  featured: '_viewAllList',
  platform_id: '_pinnedItemProjectsListView',
  platforms: '_viewAllList',
  sort: '_viewAllList',
  topic_id: '_pinnedItemProjectsListView',
  topics: '_viewAllList',
  video: '_viewAllList',
};

const getLinksForSort = (section) => {
  const url = section.value === 'featured_date' ? '/projects?by=featured&sort=featured_date' : `/projects?sort=${section.value}`;

  return {
    external: url,
    internal: url,
  };
};

const getPlatformsListFacet = (section, allSections, i) => {
  const ignored = negativeFacetsForList(section, allSections, i, 'platform_id', 'id');

  return ignored.concat('model:Platform');
};

const getTopicsListFacet = (section, allSections, i) => {
  const ignoredTopics = negativeFacetsForList(section, allSections, i, 'topic_id', 'id');

  return ignoredTopics.concat('display_on_home:true').concat('model:TopicChannel');
};

export default ({ doScroll = false } = {}, ctx = null) => ({
  _getViewMethodNameForSectionKey: (sectionKey) => SECTION_KEY_TO_VIEW_METHOD[sectionKey] || null,
  components: {
    contest_id: (props) => React.createElement(ContestCard, props),
    platform_id: (props) => React.createElement(ChannelCard, { ...props, channelType: 'platform' }),
    platforms: (props) => React.createElement(LogoCard, {
      ...props,
      classList: { wrapper: viewAllStyles.scrollerItem },
    }),
    sort: (props) => React.createElement(ProjectCard, {
      itemIndex: props.i,
      overrideTracking: props.overrideTracking,
      project: props.record,
      ...projectProps,
    }),
    topic_id: (props) => React.createElement(ChannelCard, { ...props, channelType: 'topic' }),
    topics: (props) => React.createElement(LogoCard, {
      ...props,
      classList: { wrapper: viewAllStyles.scrollerItem },
    }),
    video: (props) => React.createElement(VideoCard, {
      ...props,
      classList: { wrapper: viewAllStyles.scrollerItemProject },
      item: props.record,
      onClick: (e, item, itemIndex, sectionIndex) => {
        e.preventDefault();

        return ctx ? ctx.summonVideoDialog(item, itemIndex, sectionIndex) : console.warning('You forgot to bind a callback for homeCofig videos!');
      },
    }),
  },
  links: {
    contest_id: (section, contest) => ({
      external: contest.url,
      internal: '',
    }),
    platform_id: (section, platform) => ({
      external: platform.url ? platform.url : 'javascript:void(0);',
      internal: platform.slug ? `${platform.slug}/projects` : '/',
      view_all: platform.url ? `${platform.url}/projects` : 'javascript:void(0);',
    }),
    platforms: () => ({
      external: '/channels/platforms',
      internal: '/channels/platforms',
    }),
    sort: (section) => getLinksForSort(section),
    topic_id: (section, topic) => ({
      external: topic.url ? topic.url : 'javascript:void(0);',
      internal: topic.url ? topic.url : '/',
      view_all: topic.url ? `${topic.url}/projects` : 'javascript:void(0);',
    }),
    topics: () => ({
      external: '/channels/topics',
      internal: '/channels/topics',
    }),
    video: () => ({
      external: '/videos',
      internal: '',
    }),
  },
  listFacet: {
    platforms: (section, allSections, i) => getPlatformsListFacet(section, allSections, i),
    sort: (section) => [],
    topics: (section, allSections, i) => getTopicsListFacet(section, allSections, i),
    video: (section, allSections, i) => [],
  },
  loaderComponent: {
    platforms: () => React.createElement(DummyLogoCard, { classList: { wrapper: `${viewAllStyles.scrollerItem} ${viewAllStyles.dummyCard}` } }),
    sort: () => React.createElement(DummyProjectCard, dummyProjectProps),
    topics: () => React.createElement(DummyLogoCard, { classList: { wrapper: `${viewAllStyles.scrollerItem} ${viewAllStyles.dummyCard}` } }),
    video: () => React.createElement(DummyVideoCard, { classList: { wrapper: `${viewAllStyles.scrollerItemProject} ${viewAllStyles.dummyCard}` } }),
  },
  pagination: {
    contest_id: () => ({
      maxCardCount: doScroll ? 16 : 4,
      type: 'offset',
      length: 8,
    }),
    platform_id: () => ({
      maxCardCount: doScroll ? 16 : 4,
      type: 'offset',
      length: 8,
    }),
    platforms: () => ({
      maxCardCount: doScroll ? 32 : 8,
      type: 'page',
    }),
    sort: () => ({
      maxCardCount: doScroll ? 16 : 4,
      type: 'page',
    }),
    topic_id: () => ({
      maxCardCount: doScroll ? 16 : 4,
      type: 'offset',
      length: 8,
    }),
    topics: () => ({
      maxCardCount: doScroll ? 32 : 8,
      type: 'page',
    }),
    video: () => ({
      maxCardCount: 4,
      type: 'page',
      length: 4,
    }),
  },
  pinnedItemFacet: {
    contest_id: (section) => [`id:${section.value}`],
    platform_id: (section) => [`id:${section.value}`],
    topic_id: (section) => [`id:${section.value}`],
  },
  projectsFacet: {
    contest_id: (section) => [`contest_ids:${section.value}`],
    platform_id: (section) => [`platforms.id:${section.value}`],
    topic_id: (section) => [`topic_ids:${section.value}`],
  },
  queryMap: {
    contest_id: (section) => ({ length: (doScroll ? 7 : 3), offset: 0, qlVars: { contest_id: section.value, approved: true } }),
    platform_id: (section) => ({ length: (doScroll ? 7 : 3), offset: 0, sort: 'trending', qlVars: { channel_id: section.value, approved: true } }),
    platforms: (section) => ({ hitsPerPage: (doScroll ? 16 : 8) }),
    sort: (section) => ({ hitsPerPage: (doScroll ? 8 : 4), sort: section.value, qlVars: { approved: true } }),
    topic_id: (section) => ({ length: (doScroll ? 7 : 3), offset: 0, sort: 'published', qlVars: { topic_id: section.value, approved: true } }),
    topics: (section) => ({ hitsPerPage: (doScroll ? 16 : 8) }),
    video: (section) => ({ qlVars: { ids: getInObj(['meta', 'content'], section) } }),
  },
  service: {
    contest_id: () => 'contestsService',
    platform_id: () => 'platformsService',
    platforms: () => 'platformsService',
    sort: () => 'projectsService',
    topic_id: () => 'topicsService',
    topics: () => 'topicsService',
    video: () => 'videosService',
  },
  serviceOverride: { // Temp bucket.
    sort: () => 'graphQlProjectsService',
    video: () => 'graphQlVideosService',
  },
  pinnedServiceOverride: { // Temp bucket, make sure to add the qlVars prop to queryMap above.
    contest_id: () => 'graphQlProjectsService',
    platform_id: () => 'graphQlProjectsService',
    topic_id: () => 'graphQlProjectsService',
  },
});

import { isADate } from '../utility/types';

export const CONTEST_DATE_AT_TIME_FORMAT = {
  day: 'numeric',
  hour: 'numeric',
  hourCycle: 'h12',
  minute: '2-digit',
  month: 'long',
  timeZoneName: 'short',
  year: 'numeric',
};
export const CONTEST_DATE_FORMAT = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};
export const DATE_AT_TIME_FORMAT = {
  day: 'numeric',
  hour: 'numeric',
  hourCycle: 'h12',
  minute: '2-digit',
  month: 'long',
  weekday: 'long',
  year: 'numeric',
};
export const DATE_FORMAT = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};
export const DATE_INPUT_FORMAT = { day: '2-digit', month: '2-digit', year: 'numeric' };
export const EVENT_DATE_FORMAT = { day: 'numeric', month: 'short' };
export const NEWS_SCHEDULED_FORMAT = {
  day: '2-digit',
  hour: 'numeric',
  hourCycle: 'h12',
  minute: '2-digit',
  month: 'short',
  year: 'numeric',
};
export const YEAR_ONLY_FORMAT = { year: 'numeric' };

/**
 * Private Methods
 */
const _pad = (nm, pad = '0') => nm.toString().length <= 1 ? `${pad}${nm}` : nm;

/**
 * Datetime Local Methods
 */
// Note: The 'value' param below should be in "YYYY-MM-DDTHH:mm" format. This is a standard format recognized by JS's Date constructor.
// This is used in the native browser date/time select, so it will always be in the user's local time.
const _dateObjFromDatetimeLocalValue = (value) => {
  const dateObj = new Date(value);

  return isADate(dateObj) ? dateObj : null;
};

// Note: Checks for "YYYY-MM-DDTHH:mm" format.
const _datetimeLocalValueRegexCheck = (value) => {
  const dateTimeLocalRegex = (/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/);

  return dateTimeLocalRegex.test(value);
};

// Note: #toISOString() is always zero UTC offset.
export const datetimeLocalValueToUTCTimestamp = (value) => {
  const dateObj = _dateObjFromDatetimeLocalValue(value);

  return (!!dateObj && _datetimeLocalValueRegexCheck(value)) ? dateObj.toISOString() : null;
};

export const isDatetimeLocalValueInFuture = (value) => {
  const dateObj = _dateObjFromDatetimeLocalValue(value);

  return (!!dateObj && (dateObj.valueOf() > (new Date()).valueOf()));
};

export const isDatetimeLocalValueInPast = (value) => {
  const dateObj = _dateObjFromDatetimeLocalValue(value);

  return (!!dateObj && (dateObj.valueOf() < (new Date()).valueOf()));
};

// Note: This confirms: 1.) timestamp can be converted into a valid JS date 2.) is the expected "YYYY-MM-DDTHH:mm" format.
export const isDatetimeLocalValueValid = (value) => {
  const dateObj = _dateObjFromDatetimeLocalValue(value);

  return (!!dateObj && _datetimeLocalValueRegexCheck(value));
};

/**
 * Public Methods
 */
export const dateInputValueFromParts = ({ day, month, year }, invalidFallback = '') => {
  if (day.length !== 2 || month.length !== 2 || year.length !== 4) return invalidFallback;
  const dateString = `${year}-${month}-${day}`;
  const dateObj = new Date(dateString);

  return isADate(dateObj) ? dateString : invalidFallback;
};

// Note: Duration -> 'HH:MM:SS'
export function durationToSeconds(duration) {
  const parts = duration.split(':');

  return (parseInt(parts[0]) * 3600) + (parseInt(parts[1]) * 60) + parseInt(parts[2]);
}

// Note: Contest related timestamps should all be in PT (PST/PDT depending on daylight saving time).
export function formatContestTimestampPT(timestamp, format) {
  return timestampToPrettyDate(timestamp, format, false, null, 'America/Los_Angeles');
}

export function secondsToDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const secondsMinusHrs = seconds - (hours * 3600);
  const minutes = Math.floor(secondsMinusHrs / 60);
  const secondsRemaining = secondsMinusHrs - (minutes * 60);

  return `${_pad(hours)}:${_pad(minutes)}:${_pad(secondsRemaining)}`;
}

// Note: removeUTCZ will remove the UTC specification from the timestamp. It's useful for comparing Rails' UTC timestamps (set at midnight) to a local current timestamp.
export function timeIsFuture(timestamp, removeUTCZ = false) {
  if (removeUTCZ) {
    timestamp = timestamp.replace('Z', '');
  }

  return (new Date(timestamp).valueOf() > new Date(Date.now()).valueOf());
}

export function timeIsPast(timestamp) {
  return (new Date(timestamp).valueOf() < new Date(Date.now()).valueOf());
}

export const timeNowUnixSeconds = () => Math.floor(Date.now() / 1000);

// Note: Intl.DateTimeFormat's 'en' region format will ouput 'MM/DD/YYYY'.
// Rather than mess with the region, we can restructure the output to 'YYYY-MM-DD'.
export function timestampToDateInputFormat(timestamp) {
  const dateParts = timestampToPrettyDate(timestamp, DATE_INPUT_FORMAT, false).split('/');

  return (dateParts && dateParts.length === 3) ? [dateParts[2], dateParts[0], dateParts[1]].join('-') : timestamp;
}

// Note: With toLocal, be careful of timestamps set at midnight.
export function timestampToPrettyDate(timestamp, opts = DATE_FORMAT, toLocal = true, fallback = timestamp, timeZone = 'UTC') {
  try {
    const dateObj = new Date(Number.isInteger(timestamp) ? timestamp * 1000 : timestamp);

    const dateTimeFormat = new Intl.DateTimeFormat('en', toLocal ? opts : { ...opts, timeZone });

    return dateTimeFormat.format(dateObj);
  } catch (err) {
    console.warn(err, 'Unable to format date. Double check that the timestamp is valid.');

    return fallback;
  }
}

export function timestampToRelativeTime(timestamp, numeric = true, useShorthand = false) {
  // Arrays reprsenting 1 minute, hour, day, etc. in seconds + corresponding string representations below.
  // Note: 86400*7 and 'week' were removed to match moment for now.
  const cutoffs = [60, 3600, 86400, 86400 * 30, 86400 * 365, Infinity];
  const cutoffUnits = ['second', 'minute', 'hour', 'day', 'month', 'year'];

  // JS dates needs timestamps in ms.
  const timeMs = Number.isInteger(timestamp) ? timestamp * 1000 : new Date(timestamp).getTime();

  // Get the amount of seconds between the given date and now.
  const diffInSeconds = Math.round((timeMs - Date.now()) / 1000);

  // Grab the ideal cutoff unit for the current time.
  const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(diffInSeconds));

  const relativeTimeOpts = {
    numeric: numeric ? 'always' : 'auto',
    style: useShorthand ? 'short' : 'long',
  };
  const relativeTimeObj = new Intl.RelativeTimeFormat('en', relativeTimeOpts);

  // Get the divisor to divide the seconds by.
  // e.g. If our unit is 'day' our divisor is 1 day in seconds. We can divide our seconds by this to get the # of days.
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  return relativeTimeObj.format(Math.round(diffInSeconds / divisor), cutoffUnits[unitIndex]);
}
